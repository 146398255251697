.footer {
  cursor: default;
  background: linear-gradient(#0000, #000);
  padding-top: 10vh;
  position: relative;
}

.footer h3 {
  text-transform: uppercase;
  font-weight: 900;
}

.contain {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

.contain .col {
  text-align: center;
  border-right: 1px solid #444;
  flex-grow: 1;
  flex-basis: 1px;
  margin: 20px;
  padding: 40px;
}

.contain .col ul {
  padding: 20px;
  font-weight: bold;
  list-style-type: none;
}

.contain .col ul li {
  color: #97959a;
  margin-top: 10px;
  font-weight: 300;
}

.contain .col ul li a {
  color: #97959a;
  font-weight: 300;
}

.contain .col img {
  width: 7.8rem;
}

.contain .col h3 {
  font-weight: 600;
}

.contain .col:last-child {
  border-right: 1px solid #0000;
}

@media (width <= 1200px) {
  .contain {
    flex-direction: column;
    align-items: center;
  }

  .contain .col {
    border-bottom: 1px solid #444;
    border-right: none;
  }

  .footer {
    padding-top: 5vh;
  }
}

.copy-right {
  text-align: center;
  background-color: #000;
  margin-bottom: 1rem;
  padding: .625rem;
}

.copy-right h5 {
  font-weight: 300;
}

.connects {
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  display: flex;
}

.social {
  width: 2.3rem;
  height: 2.3rem;
}

.smaller {
  width: 2rem !important;
  height: 2rem !important;
}

@media (width <= 1200px) {
  .connects {
    margin-left: -50px;
  }
}
/*# sourceMappingURL=index.784406ec.css.map */
