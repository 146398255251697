.footer {
  position: relative;
  padding-top: 10vh;
  cursor: default;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // gradient fade
  background: linear-gradient(rgba(black, 0), black);

  & h3 {
    text-transform: uppercase;
    font-weight: 900;
  }
}

.contain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // max-width: 80%;

  .col {
    flex-basis: 1;
    flex-grow: 1;
    margin: 20px;
    padding: 40px;
    border-right: 1px solid #444444;
    text-align: center;

    // .footer-image {
    // 	display: flex;
    // 	justify-content: center;
    // 	align-items: center;
    // }
    ul {
      list-style-type: none;
      padding: 20px;
      font-weight: bold;

      li {
        margin-top: 10px;
        font-weight: 300;
        color: #97959a;
        a {
          font-weight: 300;
          color: #97959a;
        }
      }
    }
    img {
      width: 7.8rem;
    }
    h3 {
      // font-weight: bold;
      font-weight: 600;
    }
  }

  .col:last-child {
    border-right: 1px solid transparent;
  }
}

@media (max-width: 1200px) {
  .contain {
    flex-direction: column;
    align-items: center;

    .col {
      border-right: none;
      border-bottom: 1px solid #444444;
    }
  }

  .footer {
    padding-top: 5vh;
  }

  //   .footer .col ul {
  //     border-right: 0px;
  //   }
  //   .col {
  //     align-items: center;
  //     text-align: center;
  //     margin-bottom: 5vh;
  //   }
}

.copy-right {
  text-align: center;
  padding: 0.625rem;
  background-color: #000;
  margin-bottom: 1rem;
  h5 {
    font-weight: 300;
  }
}

.connects {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social {
  width: 2.3rem;
  height: 2.3rem;
  // margin-left: 0px;
}

.smaller {
  width: 2rem !important;
  height: 2rem !important;
}

@media (max-width: 1200px) {
  .connects {
    margin-left: -50px;
  }
}
